










































































































































































































































































































































.message-group {
	cursor: pointer;
	background: #eee;
	border-width: 0px;
	&:hover {
		background: #ddd;
	}
	&.active {
		background: #fff;
		padding-left: 20px !important;
		border-width: 1px 0px 1px 1px !important;
		border-radius: 30px 0px 0px 30px !important;
		margin-right: 0px !important;
	}
}

.message-bubble {
	margin: 5px;
	max-width: 65%;
	padding: 15px;
	border-radius: 30px;
	background: #666;
	color: #fff;
	border-top-left-radius: 0px;
	&.mine {
		background: #63d8f1;
		color: #fff;
		border-bottom-right-radius: 0px;
		border-top-left-radius: 30px;
	}
}

@media only screen and (max-width: 1026px) {
	.desktop-only {
		display: none;
	}
	.mobile-only {
		display: block !important;
	}
}

.placeholder-message-group {
	animation-name: stretch;
	animation-duration: 2.0s;
	animation-timing-function: ease-out;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-play-state: running;
	padding: 15px;
	margin: 20px;
	border-radius: 5px;
	background: #ddd;
	&:first-child {
		border-radius: 30px 0px 0px 30px;
		margin-right: 0px;
	}
	.text-line {
		border-radius: 6px;
		padding: 6px;
		background: linear-gradient(90deg, rgba(180,180,180,.25) 0%, rgba(86,86,98,.15) 35%, rgba(171,171,171,.05) 100%);
	}
}

@keyframes stretch {
	0% {
		transform: scale(1);
		opacity: 0.5
	}

	50% {
	}

	100% {
		transform: scale(1.01);
		opacity: 1
	}
}
